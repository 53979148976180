import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Boxes = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );
  console.log(deshboard);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);
  console.log("desh", deshboard);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Total User</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.user || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/tips")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Free Tips</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText color-risk'>{deshboard?.free || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/premium_tips")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Premium Tips
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.premium || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/subscription-plans")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Subscription Plan
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText color-risk'>{deshboard?.subscriptionplan || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/stocks")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Stocks
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.stocks || 0}</div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Boxes;
