import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

function Category() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.category_list ? state?.allapi?.category_list : {}
  );

  console.log(users_list);

  const listdata = users_list?.data ? users_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = async (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);
    await dispatch(allapiAction.delete_category({ id: e, page: page }));
    dispatch(allapiAction.allcategory(page));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (!cateDetail?.name) {
      toast.error("please enter name");
    }
    if (!cateDetail?.message) {
      toast.error("please enter message");
    }
    if (!cateDetail?.type) {
      toast.error("please enter type");
    }
    if (!cateDetail?.tip_type) {
      toast.error("please enter tip_type");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("id", cateDetail?.id);
      data.append("name", cateDetail?.name);
      data.append("message", cateDetail?.message);
      data.append("type", cateDetail?.type);
      data.append("tip_type", cateDetail?.tip_type);
      await dispatch(allapiAction.update_category(data));
      // dispatch(adminAction.getRoleList('dhsg'))
      dispatch(allapiAction.allcategory(page));
      setIsModalOpen(false);
      return () => {};
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
      const files = event.target.files;
      setSelectedFile(files);
      // Set the file object directly to cateDetailAdd.url
      setcateDetailAdd((prev) => ({
        ...prev,
        url: file, // Store the file object directly
      }));
    }
  };

  const hendleTochangeCategoryAdd = (event) => {
    const { name, value } = event.target;

    setcateDetailAdd((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "type" ? { message: "", url: "" } : {}),
    }));
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };
  const handleOkAdd = async () => {
    console.log("Current cateDetailAdd state:", cateDetailAdd);

    if (!cateDetailAdd?.name) {
      toast.error("Please enter a name");
      return;
    }
    if (cateDetailAdd?.type === "text" && !cateDetailAdd?.message) {
      toast.error("Please enter a message");
      return;
    }
    if (!cateDetailAdd?.type) {
      toast.error("Please select a type");
      return;
    }
    if (
      (cateDetailAdd?.type === "url" && !cateDetailAdd?.url) ||
      (["image", "video", "audio"].includes(cateDetailAdd?.type) &&
        !cateDetailAdd?.url)
    ) {
      toast.error(`Please upload a valid file for ${cateDetailAdd?.type}`);
      return;
    }

    const data = new FormData();
    data.append("page", 1);
    data.append("name", cateDetailAdd?.name);
    data.append("type", cateDetailAdd?.type);

    if (cateDetailAdd?.type === "text") {
      data.append("message", cateDetailAdd?.message);
    } else {
      // selectedFile
      data.append("url", cateDetailAdd?.url);
      if (selectedFile) {
        for (let i = 0; i < selectedFile?.length; i++) {
          data.append("files[" + i + "]", selectedFile[i]);
        }
      } else {
      }
    }

    try {
      await dispatch(allapiAction.create_quiz_category(data));
      // toast.success("Category created successfully");

      // Reset form state
      setcateDetailAdd({
        name: "",
        message: "",
        type: "",
        tip_type: "free",
        url: "",
      });

      dispatch(allapiAction.allcategory(1));
      setAddModalOpen(false);
    } catch (error) {
      console.error("Error during API call:", error);
      toast.error("Error creating category");
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Tip type",
      dataIndex: "tip_type",
      key: "tip_type",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.allcategory(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.allcategory(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.allcategory(e));

      return () => {};
    }
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Tips"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="message"
            name="message"
            value={cateDetail?.message}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="message"
            name="message"
            value={cateDetail?.message}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />

          <select
            name="type"
            className="form-control"
            value={cateDetail?.type}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          >
            <option value={""}>Select type</option>
            <option value={"text"}>text</option>
            <option value={"image"}>Image</option>
            <option value={"video"}>video</option>
            <option value={"url"}>url</option>
            <option value={"audio"}>audio</option>
          </select>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="message"
            name="message"
            value={cateDetailAdd?.message}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          /> */}

          <select
            name="tip_type"
            className="form-control"
            value={cateDetail?.tip_type}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          >
            <option value={""}>Select tip type</option>
            <option value={"free"}>Free</option>
            <option value={"premium"}>Premium</option>
          </select>
        </Col>
      </Modal>

      {/* create */}
      <Modal
        title="Create Tips"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        {/* Name Input */}
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="off"
            className="form-control"
            placeholder="Name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={hendleTochangeCategoryAdd}
          />
        </Col>

        {/* Message Input - Visible only when type="text" */}
        {cateDetailAdd?.type === "text" && (
          <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
            <textarea
              autoComplete="off"
              className="form-control"
              placeholder="Message"
              name="message"
              value={cateDetailAdd?.message}
              onChange={hendleTochangeCategoryAdd}
            />
          </Col>
        )}

        {/* URL Input */}
        {cateDetailAdd?.type === "url" && (
          <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
            <input
              autoComplete="off"
              className="form-control"
              placeholder="URL"
              name="url"
              value={cateDetailAdd?.url}
              onChange={hendleTochangeCategoryAdd}
            />
          </Col>
        )}

        {/* File Inputs for Image, Video, Audio */}
        {cateDetailAdd?.type === "image" && (
          <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
            />
          </Col>
        )}
        {cateDetailAdd?.type === "video" && (
          <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
            <input
              type="file"
              multiple
              accept="video/mp4"
              onChange={handleFileChange}
            />
          </Col>
        )}
        {cateDetailAdd?.type === "audio" && (
          <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
            <input
              type="file"
              multiple
              accept="audio/mp3"
              onChange={handleFileChange}
            />
          </Col>
        )}

        {/* Dropdown for Type Selection */}
        <Col lg={24} md={24} sm={24} xs={24} className="mt-10">
          <select
            name="type"
            className="form-control"
            value={cateDetailAdd?.type || ""}
            onChange={hendleTochangeCategoryAdd}
          >
            <option value="" disabled>
              Select Type
            </option>
            <option value="text">Text</option>
            <option value="image">Image</option>
            <option value="video">Video</option>
            <option value="url">URL</option>
            <option value="audio">Audio</option>
          </select>
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Tips"}
            subHeading={`Total ${users_list?.total || 0} Tips`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Category;
