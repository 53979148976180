import React, { useEffect, useState } from "react";
import { Col, Input, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { Button, Modal, Space } from "antd";
import { changeStatusUser } from "../../Redux/AllApi/action";

function SubscriptionList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  const [pages, setPage] = useState(1);

  console.log(typeof page);
  console.log(typeof pages);

  const listdata = users_list?.data ? users_list?.data : [];

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.getSubscriptionsSearch({ page: page, is_expired: search })
      );
    } else {
      dispatch(allapiAction.getSubscriptions(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        user_id: userId,
        active: 0,
        page: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const changeUserStatus = async (userId, currentStatus) => {
    try {
      console.log("Calling API here");
      const newStatus = !currentStatus;
      console.log(
        "New status is",
        newStatus,
        "Current status is",
        currentStatus
      );

      const data = {
        user_id: userId,
        active: newStatus,
      };

      await dispatch(changeStatusUser(data));

      await dispatch(allapiAction.getuserList());

      console.log("Status updated successfully!");
    } catch (error) {
      console.log("Error in changeUserStatus:", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return <>{item?.user?.name}</>;
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      className: "table_action_col",
      render: (value, item, index) => {
        return <>{item?.user?.mobile}</>;
      },
    },
    {
      title: "Plan Name",
      dataIndex: "planname",
      key: "planname",
      className: "table_action_col",
      render: (value, item, index) => {
        return <>{item?.plan?.name}</>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "table_action_col",
      render: (value, item, index) => {
        return <>{item?.plan?.amount}</>;
      },
    },
    {
      title: "Expire date",
      dataIndex: "expire_date",
      key: "expire_date",
    },

    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    //   key: "role",
    // },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (status, item) => (
        <Button
          //   onClick={() => changeUserStatus(item.id, item?.is_expired)}
          type={!item?.is_expired ? "primary" : "danger"}
        >
          {item?.is_expired ? "Expired" : "Not Expired"}
        </Button>
      ),
    },

    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title="Contact">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => navigate(`/users/contact/${item?.id}`)}
    //           >
    //             <SvgIcon.contacts className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip>
    //         <Tooltip title="delete">
    //           <button
    //             className="filter-button ml-3"
    //             onClick={() => showModal(item?.id)}
    //           >
    //             Delete
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];
  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/subscriptions?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/subscriptions?page=" + e + "&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            subHeading={`Total ${users_list?.total || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          <select
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value={""}>All</option>
            <option value={0}>Expired</option>
            <option value={1}>Not Expired</option>
          </select>
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default SubscriptionList;
