import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { URL } from "../../Redux/common/url";

function Box3() {
  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );
  return (
    <Row gutter={[0, 20]}>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Upi id</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.upi_id}</div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Game commision</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.gamecommision}
            </div>
          </div>
        </div>
      </Col> */}
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {/* Game commision 500 or below */}
              Whatsapp
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">{get_setting?.whatsapp}</div>
          </div>
        </div>
      </Col>

      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Mobile</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">{get_setting?.mobile}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Telegram</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.telegram}</div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Facebook</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">{get_setting?.facebook}</div>
          </div>
        </div>
      </Col> */}
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Instagram</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.instagram}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Terms condition
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.terms_condition}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Privacy policy
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.privacy_policy}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Payment condition
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.payment_condition}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">About us</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2"> {get_setting?.about_us}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <img
            src={URL?.API_BASE_URL + get_setting?.qr_image}
            style={{ width: "100%" }}
            alt=""
          />
        </div>
      </Col>
    </Row>
  );
}

export default Box3;
