import React, { useEffect, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// import DatePicker from "react-datepicker";

import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import { Button, Col, Modal, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import SvgIcon from "../const/SvgIcon";
import Box3 from "./Box3";

function Setting() {
  const [show, setShow] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showOtpBoxsata, setShowOtpBoxdata] = useState("");
  const dispatch = useDispatch();
  //   const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );

  const [userBuss, setuserBuss] = useState({});

  console.log(userBuss);

  const handleImageUpload1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setuserBuss((prev) => ({
        ...prev,
        image2: file,
      }));
    }
  };

  useEffect(() => {
    setStartDate(new Date(get_setting?.withdrawalstarttime));
    setStartDate2(new Date(get_setting?.withdrawalendtime));
    setuserBuss(get_setting);
  }, [get_setting]);

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setuserBuss({ ...userBuss, [name]: value });
  };
  useEffect(() => {
    // dispatch(adminAction.getInvoceDetails({}))
    dispatch(allapiAction.getsetting({}));

    return () => {};
  }, []);

  const submitProduct = () => {
    try {
      const data = new FormData();

      if (!userBuss?.upi_id) {
        toast.error("please enter referAmount");
      } else if (!userBuss?.whatsapp) {
        toast.error("please enter game commision");
      } else if (!userBuss?.mobile) {
        toast.error("please enter withdrwal commission");
      } else if (!userBuss?.telegram) {
        toast.error("please enter maxmum withdrwal");
      } else if (!userBuss?.facebook) {
        toast.error("please enter minimum withdrwal");
      } else if (!userBuss?.about_us) {
        toast.error("please enter minimum recharge");
      } else if (!userBuss?.terms_condition) {
        toast.error("please enter maxmum recharge");
      } else {
        // setLoading(true);
        // gstNo
        // companyName
        // email
        // address

        data.append("about_us", userBuss?.about_us);
        data.append("facebook", userBuss?.facebook);
        data.append("id", userBuss?.id);
        data.append("instagram", userBuss?.instagram);
        data.append("mobile", userBuss?.mobile);
        data.append("payment_condition", userBuss?.payment_condition);
        data.append("privacy_policy", userBuss?.privacy_policy);
        data.append("telegram", userBuss?.telegram);
        data.append("terms_condition", userBuss?.terms_condition);
        data.append("upi_id", userBuss?.upi_id);
        data.append("whatsapp", userBuss?.whatsapp);
        if (userBuss?.image2) {
          data.append("qr_image", userBuss.image2);
        }
        // data.append('companyName', userBuss?.companyName)
        // data.append('email', userBuss?.email)
        // data.append('address', userBuss?.address)
        // data.append('fb', userBuss?.fb)
        // data.append('insta', userBuss?.insta)
        // data.append('twitter', userBuss?.twitter)
        // data.append('youtube', userBuss?.youtube)
        // data.append('number', userBuss?.number)
        // data.append('pinterst', userBuss?.pinterst)

        // data.append('invoiceLogo', images[0]?.file)

        // console.log(startDate);
        // console.log(startDate2);

        dispatch(allapiAction.createSetting(data));
        // setShow(false);
        // setShowOtpBox(true)
        setShowOtpBox(false);
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  //   const submitChangeVerify2 = () => {
  //     if (!showOtpBoxsata) {
  //       toast.error('please enter otp')
  //     } else if (showOtpBoxsata?.length < 6 || showOtpBoxsata?.length > 6) {
  //       toast.error('otp invalid')
  //     } else {
  //       dispatch(
  //         allapiAction.verifyWhatsapp({
  //           whatsappNumber: userBuss?.whatsappNumber,
  //           otp: showOtpBoxsata,
  //         })
  //       )
  //       setShowOtpBox(false)
  //       setShow(false)
  //     }
  //   }

  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Setting"}
            // subHeading={`Total ${get_transaction_list?.totalDocs || 0} Transaction`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Update">
            <span className="jop_btn" onClick={handleShow}>
              Edit
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>
      <Modal
        title="Setting"
        open={show}
        onOk={submitProduct}
        onCancel={handleClose}
      >
        <div className="card mx-auto ">
          {/* {!showOtpBox ? ( */}
          <div className="card-body">
            {/* <h4 className="card-title mb-4">Feature Image</h4> */}

            <div className="mb-3">
              <label className="form-label">Qr Image</label>
              <input
                type="file"
                id="image-upload"
                name="image"
                className="form-control"
                accept="image/*"
                // value={cateDetail?.image}
                onChange={(e) => {
                  handleImageUpload1(e);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">UPI Id</label>
              <input
                name="upi_id"
                className="form-control"
                placeholder="Enter upi_id"
                type="text"
                value={userBuss?.upi_id}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Whatsapp </label>
              <input
                name="whatsapp"
                className="form-control"
                placeholder="Enter whatsapp"
                type="number"
                value={userBuss?.whatsapp}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Mobile</label>
              <input
                name="mobile"
                className="form-control"
                placeholder="Enter mobile"
                type="number"
                value={userBuss?.mobile}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Telegram</label>
              <input
                name="telegram"
                className="form-control"
                placeholder="Enter  telegram"
                type="text"
                value={userBuss?.telegram}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>

            {/* <div className="mb-3">
              <label className="form-label">Facebook</label>
              <input
                name="facebook"
                className="form-control"
                placeholder="Enter  facebook"
                type="text"
                value={userBuss?.facebook}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div> */}
            <div className="mb-3">
              <label className="form-label">Instagram</label>
              <input
                name="instagram"
                className="form-control"
                placeholder="Enter Instagram"
                type="text"
                value={userBuss?.instagram}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Terms & Conditions</label>
              <input
                name="terms_condition"
                className="form-control"
                placeholder="Enter terms_condition"
                type="text"
                value={userBuss?.terms_condition}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Privacy Policy</label>
              <input
                name="privacy_policy"
                className="form-control"
                placeholder="Enter privacy_policy"
                type="text"
                value={userBuss?.privacy_policy}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Payment Condition</label>
              <input
                name="payment_condition"
                className="form-control"
                placeholder="Enter payment_condition"
                type="text"
                value={userBuss?.payment_condition}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">About Us</label>
              <input
                name="about_us"
                className="form-control"
                placeholder="Enter about_us"
                type="text"
                value={userBuss?.about_us}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      <div className="mt-30">
        <Box3 />
      </div>
    </div>
  );
}

export default Setting;
