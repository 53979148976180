import { Col, Input, Row } from "antd";
import React, { useState } from "react";
// import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import IMAGES from "../const/Image";
// import { UserServices } from "../../../redux/Services";
// import { validateLogin } from "../../../utils/validation";
// import LeftSection from "../Component/LeftSection";
// import IMAGES from "../../../const/Image";
import { validateLogin } from "../utils/validation";
import LeftSection from "./LeftSection";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const [show, setShow] = useState(false);
  //   const { mutate: Login } = useMutation((data) =>
  //     dispatch(UserServices.loginApi(data, navigate))
  //   );
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    const validationErrors = validateLogin(values);
    let noErrors = Object.keys(validationErrors).length == 0;
    setErrors(validationErrors);
    if (noErrors) {

        // userLogin


    //   Login(values);

      dispatch(allapiAction.userLogin({email:values?.Email,password:values?.Password}, navigate));
      return () => {};
    }
  };
  return (
    <div className="loginPage">
      <Row gutter={[0, 16]}>
        <Col md={9} sm={24} xs={24} className="mh-100">
          <LeftSection />
        </Col>
        <Col
          md={15}
          sm={24}
          xs={24}
          className="flex justify-content-center align-items-center mh-100"
        >
          <div className="loginFormbox">
            <div className="flex justify-content-center">
              <img src={IMAGES.LogoHeader} alt="" className="scale_img mb-30" width={"33%"}/>
            </div>
            {/* <div className='welcomeText'>Welcome to LPL Admin</div> */}
            <p className="fw-600 fs-16">Sign in to your account</p>
            <div className="loginForm">
              <div className="form-group">
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control ant-email"
                    autoComplete="new-off"
                    placeholder="Email"
                    name="Email"
                    onChange={handleChange}
                    value={values?.Email || ""}
                  />
                </div>
                {errors?.Email && <p className="error">{errors?.Email}</p>}
              </div>
              <div className="form-group">
                <div className="input-group">
                  <Input.Password
                    type={show ? "text" : "password"}
                    className="form-control"
                    autoComplete="new-off"
                    placeholder="Password"
                    name="Password"
                    onChange={handleChange}
                    value={values?.Password || ""}
                  />
                </div>
                {errors?.Password && (
                  <p className="error">{errors?.Password}</p>
                )}
              </div>
              {/* <div className='box_footer'>
                                <div><Link className="fw-500 f-pass" to={"/forgot-password"}>Forgot password?</Link></div>
                            </div> */}
              <button
                type="button"
                className="gradient-button w-100"
                onClick={() => handleLogin()}
              >
                Sign In
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
