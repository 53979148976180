import React, { useEffect, useState } from "react";
import { Col, Input, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { Button, Modal, Space } from "antd";
import { changeStatusUser } from "../../Redux/AllApi/action";
import { toast } from "react-toastify";

function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );
  const plan_list = useSelector((state) =>
    state?.allapi?.category_list ? state?.allapi?.category_list : {}
  );
  const [pages, setPage] = useState(1);

  console.log(typeof page);
  const listdata2 = Array.isArray(plan_list?.data)
    ? plan_list.data
    : Array.isArray(plan_list)
    ? plan_list
    : [];
  console.log("user list for subscription", listdata2);

  const listdata = users_list?.data ? users_list?.data : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getuserListSearch({ page: page, search: search }));
    } else {
      dispatch(allapiAction.getuserList(page));
    }

    return () => {};
  }, [location]);
  useEffect(() => {
    dispatch(allapiAction.get_subscription_plan(1));

    return () => {};
  }, []);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [userId, setuserId] = useState({});
  const [userId1, setuserId1] = useState({});
  const [userId1s, setuserId1s] = useState({});

  const hendleTochangep = (e) => {
    const { name, value } = e.target;
    setuserId1s({ ...userId1s, [name]: value });
  };

  console.log(userId1);

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const showModal1 = (e) => {
    console.log(e);
    setuserId1(e);
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    if (!userId1s?.subscription_id) {
      return toast.error("Please enter subscription id");
    }
    if (!userId1s?.transaction_mode) {
      return toast.error("Please enter transaction mode");
    }
    if (
      userId1s?.transaction_mode == "Upi" ||
      userId1s?.transaction_mode == "Net-Banking"
    ) {
      if (!userId1s?.transaction_id) {
        return toast.error("Please enter transaction id");
      }
    }

    dispatch(
      allapiAction.addUserSubscription({
        user_id: userId1?.id,
        subscription_id: userId1s?.subscription_id,
        transaction_id: userId1s?.transaction_id,
        transaction_mode: userId1s?.transaction_mode,

        page: page,
      })
    );
    setuserId1({});
    setIsModalOpen1(false);
    return () => {};
  };
  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        user_id: userId,
        active: 0,
        page: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const changeUserStatus = async (userId, currentStatus) => {
    try {
      console.log("Calling API here");
      const newStatus = !currentStatus;
      console.log(
        "New status is",
        newStatus,
        "Current status is",
        currentStatus
      );

      const data = {
        user_id: userId,
        active: newStatus,
      };

      await dispatch(changeStatusUser(data));

      await dispatch(allapiAction.getuserList());

      console.log("Status updated successfully!");
    } catch (error) {
      console.log("Error in changeUserStatus:", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {item?.country_code} {item?.mobile}
          </>
        );
      },
    },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (status, item) => (
        <Button
          onClick={() => changeUserStatus(item.id, status)}
          type={status ? "primary" : "default"}
        >
          {status ? "Deactivate" : "Activate"}
        </Button>
      ),
    },
    // {
    //   title: "Actions",
    //   dataIndex: "Actions",
    //   key: "Actions",
    //   render: (status, item) => (
    //     <Button onClick={() => showModal1(item)} type={"primary"}>
    //       Subscription
    //     </Button>
    //   ),
    // },

    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {item?.subscription == null && (
              <Button onClick={() => showModal1(item)} type={"primary"}>
                Subscription
              </Button>
            )}
            {item?.subscription !== null && (
              <Tooltip title="View">
                <span
                  className="cursor-pointer"
                  onClick={() => showModal(item)}
                >
                  <SvgIcon.Visibility className="scale-icon edit-icon" />
                </span>
              </Tooltip>
            )}
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];
  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/users?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/users?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="Subscription"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
      >
        <p>
          <span style={{ fontWeight: "600" }}>Name : </span> {userId?.name}
        </p>
        <p>
          <span style={{ fontWeight: "600" }}>Mobile : </span> {userId?.mobile}
        </p>
        <p>
          <span style={{ fontWeight: "600" }}>Duration : </span>{" "}
          {userId?.subscription?.duration}
        </p>
        <p>
          <span style={{ fontWeight: "600" }}>Expire date : </span>{" "}
          {userId?.subscription?.expire_date}
        </p>
      </Modal>
      <Modal
        title="Subscription"
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
      >
        <p>
          <span>Name:</span> {userId1?.name}
        </p>
        <p>
          <span>Number:</span> {userId1?.mobile}
        </p>

        <select
          className="form-control"
          value={userId1s?.transaction_mode}
          onChange={hendleTochangep}
          name="transaction_mode"
        >
          <option value="">Select Payment Mode</option>
          <option value="Upi">Upi</option>
          <option value="Cash">Cash</option>
          <option value="Net-Banking">Net-Banking</option>
        </select>

        <input
          className="form-control mt-3"
          value={userId1s?.transaction_id}
          onChange={hendleTochangep}
          name="transaction_id"
          placeholder="Transaction Id"
        />
        <select
          className="form-control mt-3"
          value={userId1s?.subscription_id}
          onChange={hendleTochangep}
          name="subscription_id"
        >
          <option value="">Select Plan</option>
          {listdata2 &&
            listdata2?.map((item, index) => {
              return (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
        </select>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            subHeading={`Total ${users_list?.total || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default User;
