import LogoHeader from "./../assets/images/TradingLogo.jpg";
import LogoShort from "./../assets/images/TradingLogo.jpg";
import Documenticon from "./../assets/images/icons/folder.png";

const IMAGES = {
  LogoHeader: LogoHeader,
  LogoShort: LogoShort,
  Documenticon: Documenticon,
};
export default IMAGES;
